import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CallClient, CallAgent, VideoStreamRenderer, LocalVideoStream } from "@azure/communication-calling";
import { AzureCommunicationTokenCredential } from '@azure/communication-common';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from 'react-router-dom';

import {
    faMicrophone,
    faMicrophoneSlash,
    faVideo,
    faVideoSlash,
    faPhone,
} from "@fortawesome/free-solid-svg-icons";

import FlipCameraIosIcon from '@material-ui/icons/FlipCameraIos';
import { getCallDetails, cancelVideCall, getCustomerLatLong } from "../../action/azureCalling";
import './styles.css';

import { _getStorageValue, _getStorageValueRapid } from "../../comman/localStorage";
import { AES_KEY, USER_ID } from "../../comman/constants";
import { toast } from "react-toastify";

var deviceManager;
var call;
// var callAgent;
var incomingCall;
var callAgent;
var localVideoStream
var localVideoStreamRenderer
var camera = 0
var mute = false;
let userID = "";
let reqId ="";
var connectedDevice=""

const AzureVideoRoom = ({isDeskTop=false,...props}) => {
    const ref = useRef(null);
    const history = useHistory();
    const startCallButton = useRef(null);   
    const hangUpCallButton = useRef(null);
    const startVideoButton = useRef(null);
    const stopVideoButton = useRef(null);
    const connectedLabel = useRef(null);
    const remoteVideosGallery = useRef(null);
    const localVideoContainer = useRef(null);
    const localMute = useRef(null);
    const remoteMute = useRef(null)
    const [startCallDisabled, setStartCallDisabled] = useState(true);
    const [startVideoDisabled, setStartVideoDisabled] = useState(true);
    const [stopVideoDisabled, setStopVideoDisabled] = useState(true);
    const [hangUpCallDisabled, setHangUpCallDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [load, setLoad] = useState(false);
    const [callConnected, setCallConnected] = useState(false);
    const [startCall, setStartCall] = useState(false);
    const [stopCall, setStopCall] = useState(false)
    const [calleeAcsUserId, setCalleeAcsUserId] = useState("");
    const [isMute, setMute] = useState(false);
    const [stopVideo, setStopVideo] = useState(false);
    const [remoteMuteIcon , setRemoteMuteIcon] = useState(false);
    const [localMuteIcon, setLocalMuteIcon] = useState(false);
    const [isAgent, setIsAgent] = useState(false);
    const [customerInitializeCall, setCustomerInitializeCall] = useState(false);
    const [startbtn,setStartbtn] = useState(false);
    const [remoteParticiapantData,setRemoteParticipantData] = useState(false);
     const [show,setShow]=useState(false);
     const [isHangup,setHangup]=useState(false);
     const [latlong,setLatlong] = useState("")

    useEffect(() => {
        var azureToken =  isDeskTop ?props?.azureToken??"" :new URLSearchParams(props.location.search).get("azureToken");
        var agentID = isDeskTop ?props?.customerID??"" : new URLSearchParams(props.location.search).get("customerID");
        // startCallToNative();
        if(!!azureToken&&!!agentID){ //agent side
            setIsAgent(true)
            setCalleeAcsUserId(agentID);
            initializeCallAgent(azureToken);
        }else{ // customer side
            console.log("callDataValue",props.callDataValue)
            if(props.callDataValue.length===0){
                setCustomerInitializeCall(false)
                _getStorageValueRapid("USER_ID_RAPID").then((uId) => {
                    userID   = uId
                    _getStorageValueRapid("USER_NAME").then((user) => {
                        props.getCallDetails(userID, user, onSuccessCallback, onFailureCallback);
                      });   
                      });
                setIsAgent(false)
            }else{
                if(props.callDataValue[0].call_status== "Call has not been created"){
                    // toast.error("Call is not created...", {
                    //     position: toast.POSITION.BOTTOM_CENTER,
                    // });
                    history.push("/customer-connect?call=true");
                    setCustomerInitializeCall(true)
                    setIsAgent(false)                      
                }else{
                    setCalleeAcsUserId(props.callDataValue[0].bank_id);
                    initializeCallAgent(props.callDataValue[0].token);
                    setCustomerInitializeCall(false)
                }
            }
               
        }

        return () =>{
            console.log("Hangup115");
            hangUpCall()
            // props.endCallBack();
        }
    }, [])

    function handleEvent(message) {
        hangUpCall()
    }

    useEffect(() => {
        document.addEventListener("message", (event) => {
            const message = event.data;
            handleEvent(message);
         });
    
        return () =>{
          document.removeEventListener("message", (event) => {
            const message = event.data;
            handleEvent(message);
         });
        hangUpCall();
        }
      }, [])

    const onSuccessCallback = (data) => {
            if(data.data[0].call_status== "Call has not been created"){
                // toast.error("Call is not created...", {
                //     position: toast.POSITION.BOTTOM_CENTER,
                // });
                history.push("/customer-connect");
                setCustomerInitializeCall(true)
                setIsAgent(false)                      
            }else{
                setCalleeAcsUserId(data.data[0].bank_id);
                initializeCallAgent(data.data[0].token);
                setCustomerInitializeCall(false)
            }
    }
    const onFailureCallback = () => {
        history.push("/customer-connect");
        toast.error("Token is expired please try again", {
            position: toast.POSITION.BOTTOM_CENTER,
        });
    }

    const createLocalVideoStream = async () => {
        const callClient = new CallClient();
        // Set up a camera device to use.
        deviceManager = await callClient?.getDeviceManager();
        let numOfCam = (await deviceManager.getCameras()).length;
        console.log("numOfCam",await deviceManager.getCameras())
        // sendDataToReactNativeApp(JSON.stringify(await deviceManager.getCameras()))
        if (numOfCam>=2) {
            const cameras=(await deviceManager.getCameras())[camera];
            return new LocalVideoStream(cameras);
        } else if (numOfCam == 1) {
            const cameras=(await deviceManager.getCameras())[0];
            return new LocalVideoStream(cameras);
        } else {
            toast.error("No camera device found on this system ...", {
                position: toast.POSITION.BOTTOM_CENTER,
            });
        }
    }
    /**
    * Display your local video stream preview in your UI
    */
    const displayLocalVideoStream = async (localVideoStream) => {
        try {

            localVideoStreamRenderer = new VideoStreamRenderer(localVideoStream);
            const view = await localVideoStreamRenderer.createView();
            if (!!view.target) {
                localVideoContainer.current.hidden = false;
                localVideoContainer.current.appendChild(view.target);
            }
        } catch (error) {
            console.error(error);
        }
    }

    /**
    * Remove your local video stream preview from your UI
    */
    const removeLocalVideoStream = async () => {
        try {
            localVideoContainer.current.style.color =  `white`;
            localVideoStreamRenderer.dispose();
        } catch (error) {
            console.error(error);
        }
    }

    /**
     * Using the CallClient, initialize a CallAgent instance with a CommunicationUserCredential which will enable us to make outgoing calls and receive incoming calls. 
     * You can then use the CallClient.getDeviceManager() API instance to get the DeviceManager.
     */
    const initializeCallAgent = async (token) => {
        setLoad(true);
        console.log("tokenData",token)
        try {
            const callClient = new CallClient();
            const tokenCredential = new AzureCommunicationTokenCredential(token.trim());
            let callAgentNew = await callClient.createCallAgent(tokenCredential)
            // Set up a camera device to use.
            deviceManager = await callClient.getDeviceManager();
            await deviceManager.askDevicePermission({ video: true });
            await deviceManager.askDevicePermission({ audio: true });
            // Listen for an incoming call to accept.
            callAgentNew.on('incomingCall', async (args) => {
                try {
                    var azureToken =  isDeskTop ?props?.azureToken??"" :new URLSearchParams(props.location.search).get("azureToken");
                    var agentID = isDeskTop ?props?.customerID??"" : new URLSearchParams(props.location.search).get("customerID");
                    // Inspect the initial call.id value.
                    incomingCall = args.incomingCall;
                    try {
                        setStartCall(true)
                        const localVideoStream = await createLocalVideoStream();
                        const videoOptions = localVideoStream ? { localVideoStreams: [localVideoStream] } : undefined;
                        call = await incomingCall.accept({ videoOptions });
                        subscribeToCall(call,true);
                    } catch (error) {
                        console.log("accept call error", error);
                    }
                    incomingCall.on('callEnded', args => {
                        if(args.callEndReason && args.callEndReason.code == 487 && (!azureToken && !agentID)){
                            history.push("/customer-connect");
                            toast.error("Issue with connection Please try agin", {
                                position: toast.POSITION.BOTTOM_CENTER,
                            });
                        }
                    });
                    setStartCallDisabled(true)
                    setIsLoading(true)
                } catch (error) {
                    console.log("error",error);
                }
            });
            callAgent = callAgentNew
            setLoad(false)
            setStartCallDisabled(false);
            setIsLoading(false)
        } catch (error) {
            console.log("error initial", error);
            if(isDeskTop){
                props.endCallBack(false);
                toast.error("Issue with Intiating the call", {
                    position: toast.POSITION.BOTTOM_CENTER,
                });
            }
        }
    }
    /**
     * Place a 1:1 outgoing video call to a user
     * Add an event listener to initiate a call when the `startCallButton` is clicked:
     * First you have to enumerate local cameras using the deviceManager `getCameraList` API.
     * In this quickstart we're using the first camera in the collection. Once the desired camera is selected, a
     * LocalVideoStream instance will be constructed and passed within `videoOptions` as an item within the
     * localVideoStream array to the call method. Once your call connects it will automatically start sending a video stream to the other participant. 
     */

    const startCallAgent = async () => {
        // setStartCall(true)
        setLoad(true)
        try {
            const localVideoStream = await createLocalVideoStream();
            const videoOptions = localVideoStream ? { localVideoStreams: [localVideoStream] } : undefined;
            call = await callAgent.startCall([{ communicationUserId: calleeAcsUserId }], { videoOptions });
            // Subscribe to the call's properties and events.
            subscribeToCall(call);
            // Subscribe to the call's properties and events.               
            // setLoad(false);
        } catch (error) {
            console.log('startCallAgent error',error);
        }
    }

    const acceptCallButton = async (data) => {
        try {
            setStartCall(true)
            const localVideoStream = await createLocalVideoStream();
            const videoOptions = localVideoStream ? { localVideoStreams: [localVideoStream] } : undefined;
            call = await incomingCall.accept({ videoOptions });
            subscribeToCall(call,data);
            if(!data){
                    hangUpCall("")
            }
            // Subscribe to the call's properties and events.
        } catch (error) {
            console.log("accept call error", error);
        }
    }

    /**
     * Subscribe to a call obj.
     * Listen for property changes and collection updates.
     */

    const subscribeToCall = (call) => {
        try {
            var azureToken =  isDeskTop ?props?.azureToken??"" :new URLSearchParams(props.location.search).get("azureToken");
            var agentID = isDeskTop ?props?.customerID??"" : new URLSearchParams(props.location.search).get("customerID");
            // Inspect the initial call.id value.
            //Subscribe to call's 'idChanged' event for value changes.
            call.on('idChanged', () => {
                console.log(`Call Id changed: ${call.id}`);
            });

            // Inspect the initial call.state value.
            // Subscribe to call's 'stateChanged' event for value changes.
            call.on('stateChanged', async () => {
                if (call.state === 'Connected') {
                    if(isDeskTop){
                        getlatLong();
                    }
                    setStartCallDisabled(true)
                    setHangUpCallDisabled(false)
                    setCallConnected(true)
                    // setIsLoading(false)
                    setStartVideoDisabled(false)
                    setStopVideoDisabled(false)
                    setRemoteMuteIcon(false)
                    setLocalMuteIcon(false);
                    setMute(false);
                    setStartCall(true)
                    call.localVideoStreams.forEach(async (lvs) => {
                        localVideoStream = lvs;
                        await displayLocalVideoStream(lvs);
                    });
                    call.on('localVideoStreamsUpdated', e => {
                        e.added.forEach(async (lvs) => {
                            localVideoStream = lvs;
                            await displayLocalVideoStream(lvs);
                        });
                        e.removed.forEach(lvs => {
                            removeLocalVideoStream();
                        });
                    });
                } 
                else if (call.state === 'Disconnected') {
                    setStartCallDisabled(false)
                    setHangUpCallDisabled(true)
                    setCallConnected(false)
                    setStartVideoDisabled(true)
                    setStopVideoDisabled(true)
                    setRemoteMuteIcon(false)
                    setLocalMuteIcon(false);
                    setStartCall(false);

                    console.log("call?._callEndReason?.code",call?._callEndReason?.code);
                    // if (!!azureToken && !!agentID && call?._callEndReason?.code == 480 ) {
                    //     props.endCallBack(false);
                    // }
                    if (!!azureToken && !!agentID && call?._callEndReason?.code == 603 ) {
                        toast.error("Call Rejected ...", {
                            position: toast.POSITION.BOTTOM_CENTER,
                        });
                        props.endCallBack(false);
                    }
                    else if (!!azureToken && !!agentID && call?._callEndReason?.code == 0) {
                        toast.error("Call Ended ...", {
                            position: toast.POSITION.BOTTOM_CENTER,
                        });
                        setStartCallDisabled(false);
                        setHangUpCallDisabled(true);
                        setCallConnected(false);
                        setStartVideoDisabled(true);
                        setStopVideoDisabled(true);
                        setRemoteMuteIcon(false);
                        setLocalMuteIcon(false);
                        if(!isDeskTop){
                            window.location.reload();
                        }else{
                            if(isHangup){
                                toast.error("Call disconnected", {
                                    position: toast.POSITION.BOTTOM_CENTER,
                                });
                                props.endCallBack(false);

                            }else{
                                history.push("/customer-connect");
                            }
                        }
                        if(!isAgent){
                            history.push("/rapid?customer-connect=true");
                        }
                    }
                    else if((!!azureToken && !!agentID && call?._callEndReason?.code == 480)) {
                             startCallAgent()
                     } else {
                        console.log("CUSTOMER END");
                        setStartCallDisabled(false);
                        setHangUpCallDisabled(true);
                        setCallConnected(false);
                        setStartVideoDisabled(true);
                        setStopVideoDisabled(true);
                        setRemoteMuteIcon(false);
                        setLocalMuteIcon(false);
                        if(!!azureToken && !!agentID &&!isDeskTop){
                            window.location.reload();
                        }else{
                            setTimeout(async()=>{
                            })
                        }
                    }
                }
            });

            // Inspect the call's current remote participants and subscribe to them.
            call.remoteParticipants.forEach(remoteParticipant => {
                subscribeToRemoteParticipant(remoteParticipant);
            });
            // Subscribe to the call's 'remoteParticipantsUpdated' event to be
            // notified when new participants are added to the call or removed from the call.
            // call.on('remoteParticipantsUpdated', e => {
            //     console.log("remoteParticipantsUpdated",e);
            //     // Subscribe to new remote participants that are added to the call.
            //     e.added.forEach(remoteParticipant => {
            //         subscribeToRemoteParticipant(remoteParticipant)
            //     });
            //     // Unsubscribe from participants that are removed from the call
            //     e.removed.forEach(remoteParticipant => {
            //         console.log('Remote participant removed from the call.');
            //     });
            // });
        } catch (error) {
            console.error(error);
        }
    }



    /**
     * Subscribe to a remote participant obj.
     * Listen for property changes and collection udpates.
     */
    const subscribeToRemoteParticipant = (remoteParticipant) => {
        try {
            // Inspect the initial remoteParticipant.state value.
            // Subscribe to remoteParticipant's 'stateChanged' event for value changes.
            remoteParticipant.on('stateChanged', () => {
                console.log("remoteParticipant451",remoteParticipant)
                if (remoteParticipant.state === 'Connected') {
                  console.log(`Remote participant state changed: ${remoteParticipant.state}`,);
                } else{
                    console.log(`Remote participant state changed: ${remoteParticipant.state}`);
                }
            });

            // mute changes
            remoteParticipant.on('isMutedChanged', (event) => {
                setRemoteMuteIcon((remoteMuteIcon)=>!remoteMuteIcon)
              })
            // Inspect the remoteParticipants's current videoStreams and subscribe to them.
            remoteParticipant.videoStreams.forEach(remoteVideoStream => {
                subscribeToRemoteVideoStream(remoteVideoStream)
            });
            // Subscribe to the remoteParticipant's 'videoStreamsUpdated' event to be
            // notified when the remoteParticiapant adds new videoStreams and removes video streams.
            remoteParticipant.on('videoStreamsUpdated', e => {
                // Subscribe to new remote participant's video streams that were added.
                console.log("videoStreamsUpdated",e)
                e.added.forEach(remoteVideoStream => {
                    subscribeToRemoteVideoStream(remoteVideoStream)
                });
                // Unsubscribe from remote participant's video streams that were removed.
                  e.removed.forEach(remoteVideoStream => {
                      console.log('Remote participant video stream was removed.',remoteVideoStream);
                      if(isDeskTop&&callConnected){
                        props.endCallBack(false)
                      }
                  })
            });
        } catch (error) {
            console.error(error);
            
        }
    }

    /**
     * Subscribe to a remote participant's remote video stream obj.
     * You have to subscribe to the 'isAvailableChanged' event to render the remoteVideoStream. If the 'isAvailable' property
     * changes to 'true', a remote participant is sending a stream. Whenever availability of a remote stream changes
     * you can choose to destroy the whole 'Renderer', a specific 'RendererView' or keep them, but this will result in displaying blank video frame.
     */

    const subscribeToRemoteVideoStream = async (remoteVideoStream) => {
        let renderer = new VideoStreamRenderer(remoteVideoStream);
        let view;

        const createView = async () => {
            // Create a renderer view for the remote video stream.
            view = await renderer.createView();
            // Attach the renderer view to the UI.
            remoteVideosGallery.current.appendChild(view.target);
        }

        // Remote participant has switched video on/off
        remoteVideoStream.on('isAvailableChanged', async () => {
            try {
                if (remoteVideoStream.isAvailable) {
                    await createView();
                } else {
                    view.dispose();
                    remoteVideosGallery.current.style.backgroundColor = `white`
                    // localVideoContainer.current.style.backgroundSize =  `Cover`
                    remoteVideosGallery.current.style.color = 'white'
                }
            } catch (e) {
                console.error(e);
            }
        });
            
        // Remote participant has video on initially.
        if (remoteVideoStream.isAvailable) {
            try {
                await createView();
            } catch (e) {
                console.error(e);
            }
        }
    }

    /**
     * Start your local video stream.
     * This will send your local video stream to remote participants so they can view it.
     */
    const startVideoAgent = async () => {
        try {
            const localVideoStreamSetter = await createLocalVideoStream();
            await call.startVideo(localVideoStreamSetter);
            setStopVideo(false)
        } catch (error) {
            console.error(error);
        }
    }

    /**
     * Stop your local video stream.
     * This will stop your local video stream from being sent to remote participants.
     */
    const stopVideoAgent = async () => {
        try {
            await call.stopVideo(localVideoStream);
            setStopVideo(true)
        } catch (error) {
            console.error(error);
        }
    }

    /**
     * To render a LocalVideoStream, you need to create a new instance of VideoStreamRenderer, and then
     * create a new VideoStreamRendererView instance using the asynchronous createView() method.
     * You may then attach view.target to any UI element. 
     */

    /**
     * End current call
     */
    const hangUpCall = async (event) => {
        console.log("!!call?.hangUp",!!call?.hangUp)
        setHangup(true)
        if(!!call?.hangUp){
            if(isDeskTop){
                try{
                    await call?.hangUp(); 
                    call = {}
                    callAgent={}
                    setRemoteMuteIcon(false)
                    setLocalMuteIcon(false);
                    setMute(false);
                    // sendDataToReactNativeApp('endCall')
                    setStartCall(false)
                    props.endCallBack();
                }catch(r){
                    console.log("error",r);
                }
            }else{
                await call?.hangUp(); 
                call = {}
                callAgent={}
                setRemoteMuteIcon(false)
                setLocalMuteIcon(false);
                setMute(false);
                // sendDataToReactNativeApp('endCall')
                setStartCall(false)
                if(!isAgent){
                    history.push("/rapid?azureVideoCall=true");
                }
            }
        }
    }
  

    const captureScreenshot = () => {
       try{
        const videoElement = remoteVideosGallery.current.querySelector('video');
        const canvas = document.createElement('canvas');
        canvas.width = videoElement.videoWidth;
        canvas.height = videoElement.videoHeight;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
        const base64Image = canvas.toDataURL();
        console.log(base64Image); // This is your base64 encoded image
        if(base64Image.length > 100){
            props.takeScreenShot(base64Image);
        }else{
            toast.error("Failed to taking screenshot, Please try agin.", {
                position: toast.POSITION.BOTTOM_CENTER,
            });
        }
       }catch(error){
        toast.error("Failed due to receipient hide the camera...", {
            position: toast.POSITION.BOTTOM_CENTER,
        });
       }
      };
    

    const cutCall = () => {
        hangUpCall()
        acceptCallButton(false)
        // sendDataToReactNativeApp('RejectCall')
        setStopCall(true)
        setCallConnected(true)
        setStartCall(false);
        setRemoteMuteIcon(false)
        setLocalMuteIcon(false);
    }

    const Ringing = ({ acceptCallButton }) => {
        return (
            <div className="main-container-ring">
                <p className="message-text">Ringing...</p>
                <div className="ring-btn-container">
                    <div className="padding3"><button className={'option-button-red'} onClick={() => cutCall()} ref={hangUpCallButton} type="button" ><FontAwesomeIcon icon={faPhone} rotate={45} color="#fff" size="lg" /></button></div>
                    <div className="padding3"><button className={'option-button-green'} onClick={() => acceptCallButton(true)} ref={hangUpCallButton} type="button" ><FontAwesomeIcon icon={faPhone} rotate={45} color="#fff" size="lg" /></button></div>
                </div>

            </div>
        );
    }

    const switchCameras =async () => {
        if (camera === 0) {
            try{
                stopVideoAgent()
                const callClient = new CallClient();
            // Set up a camera device to use.
            deviceManager = await callClient?.getDeviceManager();
            console.log("await deviceManager.getCameras()",await deviceManager.getCameras())
            let numOfCam = (await deviceManager.getCameras()).length;
                camera = numOfCam - 1
                startVideoAgent() 
            }
            catch(e){
                camera = 1;
            }
         
        }
        else {
            stopVideoAgent()
            camera = 0
            startVideoAgent()
        }
    }

    const muteCall=async()=>{
        setLocalMuteIcon(!localMuteIcon)
        if(isMute==false){
            await call.mute();
        }else{
            await call.unmute()
        }
        setMute(!isMute)
    }

    // const sendDataToReactNativeApp = async (data) => {
    //     // 
    //     await   (window["ReactNativeWebView"]||window).postMessage(JSON.stringify({name:data}));
    //     if(data !== 'rejoin'){
    //         setStartCall(false);
    //     }
    //     // window.location.reload();
    // };

    const customerCreateCall = () =>{
                setCustomerInitializeCall(false)
                _getStorageValueRapid("USER_ID_RAPID").then((uId) => {
            userID   = uId
            _getStorageValueRapid("USER_NAME").then((user) => {
                props.getCallDetails(userID, user, onSuccessCallback, onFailureCallback);
              });   
              });
        setIsAgent(false)
            }

    const getlatLong=()=>{
                _getStorageValue(USER_ID).then((userId) => {
                    props.getCustomerLatLong(userId, props.reqID, successApiCallBack,failiur);
                  });
            
                  const successApiCallBack=(response)=>{
                    const latitude = response.data.customer_details[0].latitude;
                    const longitude = response.data.customer_details[0].longitude;
                    setLatlong({latitude, longitude})
                  }
                  const failiur=(data)=>{
                    setLatlong("")
                  }
            }
    return (
        <div ref={ref} id="videoCall" style={{position: 'absolute',height:"100vh",width:"100vw",top:0,left:0,backgroundColor:"#eeeeee"}}>
            <div className="video-main-container">
                {callConnected ?
                    <>
                            {!stopCall  ? <>
                                <div ref={remoteVideosGallery} className={ 'remote-video-container-desktop' }> 
                                    {remoteMuteIcon&&<div style={{display:'flex',position:'absolute',top:50,right:50}}>
                                        <div className={'option-button'} style={{background:'#fff'}}><FontAwesomeIcon icon={faMicrophoneSlash} rotate={45} color="#000" size="2x"/></div>
                                    </div>} 
                                </div>
                                </> : null}
                                <div className={'call-control-container'}>
                            </div>
                    </>
                    :
                    <div className={"main-container-connecting"}>
                    {!isAgent ? <>  <><label className={'loader-circle'}></label> <label style={{ color: '#e77817', position: 'absolute', top: '52%' }}>{"Waiting for call to initialize..."}</label></></> 
                                
                                :
                        <> {load ?  <><label className={'loader-circle'}></label> <label style={{ color: '#e77817', position: 'absolute', top: '52%' }}>{!isDeskTop?"Loading...":"Waiting for customer to join..."}</label></> :
                            <>{!startCall ?
                                <button className={'btn btn-primary'} onClick={() => startCallAgent()} ref={startCallButton} type="button" disabled={startCallDisabled}>
                                    <p>Start Call</p><FontAwesomeIcon icon={faVideo} color="#fff" size="lg" />
                                </button> : null}</>
                        }
                        </>
                    }
                </div>
                }
                {startCall ? 
                <>
                     <div  ref={localVideoContainer} className={'local-video-container'}>
                     <div style={{position:"relative",zIndex:1}}>
                        {localMuteIcon&&<div className={'option-button'} style={{display:'flex',position:"absolute",top:2,right:2,margin: '10px',width: '35px', height: '35px', background:'#fff'}}><FontAwesomeIcon icon={faMicrophoneSlash} rotate={45} color="#000" size="sm"/></div>}
                        </div> 
                          </div>
                         </>:
                          null}
            </div>
            {!!latlong && <div style={{position:"absolute",left:10,bottom:10,zIndex:999,color:"#f07d20"}}> 
                            <div><b>customer latitude :</b> {latlong.latitude}</div> 
                            <div> <b>customer longitude :</b> {latlong.longitude}</div> 
                          </div>} 
            {startCall
                ? 
                    <div className="video-controls-container" >
                       <div title={"Switch Camera"} ><button className={'switch-camera'} onClick={() => switchCameras()} ref={hangUpCallButton} type="button"  >
                       <FlipCameraIosIcon style={{ cursor: "pointer" }} /></button></div>
                        {isMute&&<div><button className={'option-button'} onClick={() => muteCall()}  type="button"  ><FontAwesomeIcon icon={faMicrophoneSlash} rotate={45} color="#fff" size="lg" /></button></div>}
                        {!isMute &&<div><button className={'option-button'} onClick={() => muteCall()} ref={hangUpCallButton} type="button" ><FontAwesomeIcon icon={faMicrophone} rotate={45} color="#fff" size="lg" /></button></div>}
                        {stopVideo ? <div><button className={'option-button'} onClick={async(e) => {
                            e.preventDefault();
                            setStartbtn(true)

                            setTimeout(()=>{
                                 startVideoAgent();
                                 setStartbtn(false)
                            },1000)
                            e.persist();

                        }} ref={startVideoButton} type="button" disabled={startbtn } ><FontAwesomeIcon icon={faVideoSlash } color="#fff" size="lg" /></button></div> :
                        <div><button className={'option-button'} onClick={async(e) =>{
                            e.preventDefault();
                            setStopVideoDisabled(true)
                                stopVideoAgent();
                                setStopVideoDisabled(false)
                            e.persist();
                        } 
                        } ref={stopVideoButton} type="button" disabled={stopVideoDisabled}><FontAwesomeIcon icon={faVideo} color="#fff" size="lg" /></button></div>}
                        <div><button className={'option-button-red'} onClick={() =>hangUpCall() } ref={hangUpCallButton} type="button" ><FontAwesomeIcon icon={faPhone} rotate={45} color="#fff" size="lg" /></button></div>
                        {isDeskTop &&<div className="filter-Button">
                        <button type="button"  disabled={props.isLoading} style={{marginLeft:10,flexDirection:"row",display:"flex",width:175,alignItems:"center",justifyContent:"center"}} className="report" onClick={() =>captureScreenshot() }>  {props.isLoading?<div className="loader"></div> :<div>Take Screenshot</div>} </button>
                        </div> }
                        <div id="show"></div>
                    </div>
                : null}
            {stopCall ? <div className="disconnected">Call Disconnected</div> : null}

        </div>
    )
}

function mapStateToProps(state) {
    // console.log("state.azureCalling",state.azureCalling)
    return {
        callDataValue: state.azureCalling.callDataValue,
    }
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getCallDetails: getCallDetails,
            cancelVideCall: cancelVideCall,
            getCustomerLatLong:getCustomerLatLong
        },
        dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(AzureVideoRoom);